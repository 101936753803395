<template>
  <div class="CustomerReceives-view-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="returnPage">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Trở về trước
        </b-button>
        <b-button
          class="btn btn-inactive ml-3"
          type="button"
          v-if="customerReceivesData.isActive && isAdmin"
          @click="handleInactive(customerReceivesData)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Inactive
        </b-button>
        <b-button
          class="btn btn-active ml-3"
          type="button"
          v-if="!customerReceivesData.isActive && isAdmin"
          @click="handleInactive(customerReceivesData)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Active
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="button"
          v-if="isAdmin"
          @click.stop="handleUpdate(customerReceivesData)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/edit-2.svg" />
          </span>
          Chỉnh sửa
        </b-button>
      </template>
    </basic-subheader>
    <b-overlay :show="loading">
      <b-container fluid class="CustomerReceives-view-page__body mb-6 mt-6">
        <b-row Class="mt-8">
          <b-col>
            <div class="text-muted">Id:</div>
            <div>
              {{ customerReceivesData.id }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">IsDeleted:</div>
            <div>
              {{ customerReceivesData.isDeleted }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">CreateDatetime:</div>
            <div>
              {{ customerReceivesData.createDatetime }}
            </div>
          </b-col>
        </b-row>
        <b-row Class="mt-8">
          <b-col>
            <div class="text-muted">UpdateDatetime:</div>
            <div>
              {{ customerReceivesData.updateDatetime }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">CreatorId:</div>
            <div>
              {{ customerReceivesData.creatorId }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">UpdaterId:</div>
            <div>
              {{ customerReceivesData.updaterId }}
            </div>
          </b-col>
        </b-row>
        <b-row Class="mt-8">
          <b-col>
            <div class="text-muted">Name:</div>
            <div>
              {{ customerReceivesData.name }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">PhoneNumber:</div>
            <div>
              {{ customerReceivesData.phoneNumber }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">DateOfBirth:</div>
            <div>
              {{ customerReceivesData.dateOfBirth }}
            </div>
          </b-col>
        </b-row>
        <b-row Class="mt-8">
          <b-col>
            <div class="text-muted">ContactSource:</div>
            <div>
              {{ customerReceivesData.contactSource }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">Gender:</div>
            <div>
              {{ customerReceivesData.gender }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">Status:</div>
            <div>
              {{ customerReceivesData.status }}
            </div>
          </b-col>
        </b-row>
        <b-row Class="mt-8">
          <b-col>
            <div class="text-muted">FollowOaFlag:</div>
            <div>
              {{ customerReceivesData.followOaFlag }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">JoinGroupchatFlag:</div>
            <div>
              {{ customerReceivesData.joinGroupchatFlag }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">DownloadAppFlag:</div>
            <div>
              {{ customerReceivesData.downloadAppFlag }}
            </div>
          </b-col>
        </b-row>
        <b-row Class="mt-8">
          <b-col>
            <div class="text-muted">LoginFlag:</div>
            <div>
              {{ customerReceivesData.loginFlag }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">BoardingBookingFlag:</div>
            <div>
              {{ customerReceivesData.boardingBookingFlag }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">BoardingTestingFlag:</div>
            <div>
              {{ customerReceivesData.boardingTestingFlag }}
            </div>
          </b-col>
        </b-row>
        <b-row Class="mt-8">
          <b-col>
            <div class="text-muted">BoardingDoneFlag:</div>
            <div>
              {{ customerReceivesData.boardingDoneFlag }}
            </div>
          </b-col>
          <b-col>
            <div class="text-muted">JoinHeathCouseFlag:</div>
            <div>
              {{ customerReceivesData.joinHeathCouseFlag }}
            </div>
          </b-col>
          <b-col> </b-col>
        </b-row>
        <b-row Class="mt-8">
          <b-col> </b-col>
          <b-col> </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      isFirstLoad: false,
      CustomerReceivesData: {},
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    isAdmin() {
      let roles = this.currentUser?.roles;
      if (roles && roles.length) {
        return roles.includes('Admin');
      } else {
        return false;
      }
    },
  },
  methods: {
    returnPage() {
      this.$router.push({
        name: 'customerReceives_list',
      });
    },
    handleUpdate(item) {
      this.$router.push({
        name: 'customerReceives_detail',
        params: {
          form_type: 'edit',
          id: item.id,
        },
      });
    },
    handleInactive(item) {
      let text = item.isActive ? 'Inactive' : 'Active';
      let btn = item.isActive ? 'btn-inactive' : 'btn-active';
      this.$swal({
        title: '',
        text: `Bạn có chắc muốn ${text} app user ${item.id} không?`,
        icon: '/media/svg/icons/SweetAlert/alert-triangle-red.svg',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: 'btn btn-secondary',
            closeModal: true,
          },
          confirm: {
            text: `${text}`,
            value: true,
            visible: true,
            className: `btn ${btn}`,
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.inactiveItem(item)
              .then(() => {
                this.loadCustomerReceivesData();
                this.$swal(
                  'Thành công!',
                  'Chúc mừng, bạn đã cập nhật thông tin CustomerReceives thành công!',
                  'success',
                );
              })
              .catch(() => {
                this.$swal('Lỗi!', 'Đã có lỗi xảy ra.', 'error');
              });
          }
        }.bind(this),
      );
    },
    async inactiveItem(item) {
      this.loading = true;
      try {
        let payload = new FormData();
        payload.append('id', item.id);
        payload.append('active', !item.isActive);
        await this.$api.put(`CustomerReceives`, payload);
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    async loadCustomerReceivesData() {
      if (!this.$route.params.id) return;
      // console.log('ABC', this.$route.params);
      try {
        this.loading = true;
        const id = this.$route.params.id;
        let { data } = await this.$api.get(`CustomerReceives/${id}`);
        this.customerReceivesData = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    // console.log('ABC');
    this.loadCustomerReceivesData();
  },
};
</script>
